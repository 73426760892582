<template>
  <v-container class="my-3">
    <!--preloader-->
    <!--    <PagePreLoader :loading="pageLoading"/>-->

    <v-row>
      <!--left side bar-->
      <v-col
        cols="12"
        lg="3"
      >
        <v-expansion-panels
          v-model="filterPanel.category"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header color="secondary">
              <h3 class="filter-header">
                All Categories
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list v-if="categoriesToView.length">
                <v-list-group
                  v-for="(category, index) in categoriesToView"
                  :key="'cat'+index"
                  v-model="category.isOpen"
                  :value="false"
                  no-action
                >
                  <template #activator>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-uppercase"
                      >
                        {{ category.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item-group
                    v-if="category.children"
                    value="name"
                  >
                    <v-list-item
                      v-for="(item, index) in category.children"
                      :key="'sub'+ index"
                      @change="selectCategory(category.slug, item.slug)"
                    >
                      <template #default>
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="item.isSelected"
                            color="primary"
                            dense
                          />
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title
                            class="text-uppercase"
                          >
                            {{ item.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list-group>
              </v-list>
              <SectionLoadingSpinner v-else />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!--price range slider-->
        <v-expansion-panels
          v-model="filterPanel.priceRange"
          class="mt-5"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header color="secondary">
              <h3 class="filter-header text-white">
                Filters
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <template v-if="price_range.isUpdated">
                <v-card-text class="text-center">
                  <v-range-slider
                    v-model="priceRangeFilter"
                    :max="price_range.max"
                    :min="price_range.min"
                    class="align-center"
                    hide-details
                    :ticks="false"
                    track-color="grey"
                    validate-on-blur
                    height="50px"
                    tick-size="50"
                    @change="updateData"
                  />

                  <div class="text-center font-weight-bold">
                    {{ priceRangeFilter[0] | currency }} - {{ priceRangeFilter[1] | currency }}
                  </div>
                </v-card-text>
                <v-card-text v-if="isMobile">
                  <v-row>
                    <!--filters-->
                    <v-col
                      cols="12"
                      lg="10"
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          lg="3"
                          md="6"
                        >
                          <div class="px-1">
                            <v-autocomplete
                              v-model="basicSorting"
                              :items="basicSortOption"
                              dense
                              hide-details
                              item-text="name"
                              item-value="value"
                              outlined
                              label="Sort By"
                              @change="updateData($event)"
                            />
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          lg="3"
                          md="6"
                        >
                          <div class="px-1">
                            <v-autocomplete
                              v-if="brandsData.length"
                              ref="brandFilter"
                              v-model="filterByBrand"
                              :items="brandsData"
                              clearable
                              dense
                              hide-details
                              item-text="name"
                              item-value="id"
                              outlined
                              label="All Brands"
                              @change="updateData"
                            />
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          lg="3"
                          md="6"
                        >
                          <div class="px-1">
                            <v-autocomplete
                              v-model="filterByShop"
                              :items="shop"
                              clearable
                              dense
                              hide-details
                              item-text="name"
                              item-value="id"
                              outlined
                              label="All Vendors"
                              @change="updateData"
                            />
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          lg="3"
                          md="6"
                        >
                          <div class="px-1">
                            <v-text-field
                              ref="search"
                              v-model="filterBySearch"
                              clearable
                              dense
                              hide-details
                              outlined
                              label="Search"
                              prepend-inner-icon="mdi-magnify"
                              @input="updateData"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!--product show layout toggle-->
                    <v-col
                      cols="12"
                      lg="2"
                    >
                      <v-spacer />
                      <div class="d-flex justify-end">
                        <!-- <v-btn class="mr-2" @click="clearFilter">Clear Filter</v-btn> -->
                        <v-btn
                          :class="{'secondary--text': viewMode === 'grid'}"
                          icon
                          @click="setMode('grid')"
                        >
                          <v-icon size="30">
                            mdi-view-grid-outline
                          </v-icon>
                        </v-btn>
                        <v-btn
                          :class="{'secondary--text': viewMode === 'list'}"
                          icon
                          @click="setMode('list')"
                        >
                          <v-icon size="35">
                            mdi-view-list
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </template>
              <SectionLoadingSpinner v-else />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>

      <!--top filters-->
      <v-col
        cols="12"
        lg="9"
      >
        <!--Top filter bar-->
        <v-card
          v-if="!isMobile"
          class="mb-5"
          outlined
        >
          <v-card-text>
            <v-row>
              <!--filters-->
              <v-col
                cols="12"
                lg="10"
              >
                <v-row>
                  <v-col
                    cols="12"
                    lg="3"
                    md="6"
                  >
                    <div class="px-1">
                      <v-autocomplete
                        v-model="basicSorting"
                        :items="basicSortOption"
                        dense
                        hide-details
                        item-text="name"
                        item-value="value"
                        outlined
                        label="Sort By"
                        clearable
                        @change="updateData($event)"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="3"
                    md="6"
                  >
                    <div class="px-1">
                      <v-autocomplete
                        v-if="brandsData.length"
                        ref="brandFilter"
                        v-model="filterByBrand"
                        :items="brandsData"
                        clearable
                        dense
                        hide-details
                        item-text="name"
                        item-value="id"
                        outlined
                        label="All Brands"
                        @change="updateData"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="3"
                    md="6"
                  >
                    <div class="px-1">
                      <v-autocomplete
                        v-model="filterByShop"
                        :items="shop"
                        clearable
                        dense
                        hide-details
                        item-text="name"
                        item-value="id"
                        outlined
                        label="All Shops"
                        @change="updateData"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="3"
                    md="6"
                  >
                    <div class="px-1">
                      <v-text-field
                        ref="search"
                        v-model="filterBySearch"
                        clearable
                        dense
                        hide-details
                        outlined
                        label="Search"
                        prepend-inner-icon="mdi-magnify"
                        @input="updateData"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <!--product show layout toggle-->
              <v-col
                cols="12"
                lg="2"
              >
                <v-spacer />
                <div class="d-flex justify-end">
                  <!-- <v-btn class="mr-2" @click="clearFilter">Clear Filter</v-btn> -->
                  <v-btn
                    :class="{'secondary--text': viewMode === 'grid'}"
                    icon
                    @click="setMode('grid')"
                  >
                    <v-icon size="30">
                      mdi-view-grid-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    :class="{'secondary--text': viewMode === 'list'}"
                    icon
                    @click="setMode('list')"
                  >
                    <v-icon size="35">
                      mdi-view-list
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <component
          :is="currentView"
          v-if="allProducts?.length !== 0"
          :loading="dataLoading"
          :products="allProducts"
        />
        <v-row
          v-else
          align-content="center"
          class="mt-5"
          justify="center"
        >
          <v-alert
            v-if="!pageLoading"
            border="right"
            colored-border
            elevation="2"
            type="warning"
          >
            <h3
              class="title"
              style="line-height: 1"
            >
              No product found
            </h3>
          </v-alert>
        </v-row>

        <!--pagination-->
        <v-card v-if="!pageLoading">
          <v-card-text
            class="text-center align-content-center justify-center mt-5"
          >
            <v-pagination
              v-model="paginate"
              :length="paginationLength"
              circle
              color="secondary"
              :total-visible="8"
              @input="updateData"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PopularProduct from '@/components/Product/PopularProduct';
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import PagePreLoader from '@/components/Utils/PagePreLoader';
import LoadingSpinner from '@/components/Utils/LoadingSpinner';
import SectionLoadingSpinner from '@/components/Utils/SectionLoadingSpinner';
import collect from 'collect.js';
import { defineComponent } from 'vue';
import { useUrlSearchParams } from '@vueuse/core';
import {isMobile} from 'mobile-device-detect';
import {getFromLocalStorage, setViewMode} from '@/utils/helper';

export default defineComponent({
  name: 'Products',
  components: { SectionLoadingSpinner, LoadingSpinner, PagePreLoader, PopularProduct },

  data: () => ({
    isMobile,
    setViewMode,
    filterPanel: {
      category: isMobile ? [] : [0],
      priceRange: isMobile ? [] : [0],
      filterGroup: isMobile ? [] : [0]
    },
    dataLoading: false,
    viewMode: getFromLocalStorage('viewMode') || 'grid',
    priceRange: [0, 0],
    tab: null,
    selectedCategories: {
      0: 0
    },
    pageLoading: false,
    categoryData: [],
    filterByBrand: null,
    initialized: false,
  }),

  computed: {
    ...mapGetters({
      categories: 'category/data',
      price_range: 'product/priceRange',
      brandsData: 'brand/brandsData' || [],
      basicSortOption: 'product/basicSortOption',
      shop: 'product/vendors',
      allProducts: 'product/allProducts',
      paginationLength: 'product/paginationLength',
    }),

    urlParams() {
      return this.$route.query;
    },

    categoriesToView() {
      return this.categoryData || [];
    },

    priceRangeFilter: {

      set(payload) {

        let payloadData = {
          min: payload[0],
          max: payload[1]
        };

        this.applyFilter({ type: 'priceRange', data: payloadData });
      },

      get() {
        const data = this.$store.getters['product/appliedPriceRange'];
        return data;
      }

    },

    paginate: {

      set(payload) {
        this.gotoTop();
        this.applyFilter({ type: 'page', data: payload });
      },

      get() {
        return this.$store.state.product.filters.page;
      }
    },

    filterByShop: {

      set(payload) {
        this.applyFilter({ type: 'shop', data: payload });
      },

      get() {
        return this.$store.state.product.filters.shop;
      }
    },

    filterBySearch: {

      set(payload) {
        this.resetPagination();
        this.applyFilter({ type: 'searchString', data: payload });
      },

      get() {
        return this.$store.state.product.filters.searchString;
      },

    },

    basicSorting: {

      set(payload) {

        let sortType = 'date';
        let sortValue = 1;

        switch (payload) {

          case 'sortBestSelling' :
            sortType = 'popular';
            sortValue = 1;
            break;

          case 'sortLowPrice' :
          case 'sortHighPrice' :
            sortType = 'price';
            sortValue = payload === 'sortLowPrice' ? 0 : 1;
            break;

          case 'sortOld' :
          case 'sortNew' :
            sortType = 'date';
            sortValue = payload === 'sortNew' ? 1 : 0;
            break;
        }

        let data = {
          sort_by: sortType,
          sort_high: sortValue
        };

        this.applyFilter({ type: 'basic', data: data });
      },
      get() {
        return '';
      }
    },

    currentView() {

      if (this.viewMode === 'grid') {
        return () => import('@/components/Product/ProductGrid');
      }

      return () => import('@/components/Product/ProductList');
    },
  },

  watch: {

    urlParams: {
      handler() {
        this.handleQueryParams();
      },
      immediate: true,
    },

    filterByBrand: {
      handler(val) {
        this.applyFilter({ type: 'brand', data: val });
      },
      immediate: true,
    },
  },

  async mounted() {

    this.pageLoading = true;

    if (!this.$store.getters['brand/hasData']) {
      await this.getBrand();
    }

    if (!this.$store.getters['product/hasVendor']) {
      await this.getVendor();
    }

    if (!this.$store.getters['category/hasData']) {
      await this.getCategory();
    }

    this.makeCategoryData();

    this.priceRange = [this.price_range.min, this.price_range.max];

    await this.handleQueryParams();
    await this.getProducts();

    this.pageLoading = false;

    this.initialized = true;
  },

  // async beforeMount() {
  //
  // },

  // beforeMount() {
  //   this.$nextTick(()=> {
  //     console.log('beforeMount');
  //   })
  // },

  beforeDestroy() {
    this.$nextTick(()=> {
      //this.priceRangeFilter = [0, 0];
      this.resetAllFilters();
      this.resetPagination();
    });
  },

  // beforeUpdate() {
  //    this.resetAllFilters();
  //    this.resetPagination();
  // },

  methods: {
    ...mapActions({
      applyFilter: 'product/applyFilter',
      getVendor: 'product/fetchVendors',
      getBrand: 'brand/getBrandData',
      getCategory: 'category/getCategoryData',
      getProducts: 'product/getProducts',
      resetPriceRange: 'product/setToInitialPriceRange',
    }),

    resetPagination() {
      this.applyFilter({ type: 'page', data: 1 });
    },

    resetAllFilters() {
      this.applyFilter({ type: 'brand', data: null });

      if (this.$refs.brandFilter) {
        this.$refs.brandFilter.reset();
      }

      if (this.$refs.search) {
        this.$refs.search.reset();
      }

      this.selectCategory();

      this.resetPriceRange();

      // this.priceRangeFilter = [0, 0];
    },

    async handleQueryParams() {

      const params = useUrlSearchParams('history');

      const brand = this.$route.query?.brand;

      const category = this.$route.query?.category;
      const subCategory = this.$route.query?.sub;

      const hasParams = !!brand || !!category || !!subCategory || !!params.search;

      if (!hasParams) {
        this.resetAllFilters();
        await this.updateData();
      }

      if (params.search) {
        this.filterBySearch = decodeURI(params.search);
        this.viewMode = 'list';
        this.filterByBrand = null;
        this.filterByShop = null;
        this.basicSorting = '';
        await this.updateData();
      }

      if (brand) {
        await this.selectCategory();
        const brandParam = collect(this.brandsData).where('slug', brand).first();
        // const brandParam = this.brandsData.find((item) => item.slug = brand);
        // console.log({currentBrand, brandParam});
        // console.log(brandParam);
        this.filterByBrand = brandParam?.id;
        await this.updateData();
      }

      if (category && subCategory) {
        this.applyFilter({ type: 'brand', data: null });
        this.filterByBrand = null;
        await this.selectCategory(category, subCategory);
        await this.updateData();
      }

    },

    makeCategoryData(category) {

      if (Array.isArray(category) && category.length) {
        this.categoryData = category;
        return;
      }

      let allCategory = this.$store.getters['category/data'];

      if (!allCategory.length) return [];

      const updatedCategories = allCategory.map((item) => ({
        ...item,
        isOpen: false,
        children: item?.children?.map((subcategory) => ({
          ...subcategory,
          isSelected: false
        }))
      }));

      this.categoryData = updatedCategories;
    },

    updateData: debounce(async function () {
      this.dataLoading = true;
      await this.getProducts();
      this.dataLoading = false;
    }, 350),

    async paginates() {
      await this.updateData();
    },

    setMode(mode) {
      setViewMode(mode);
      this.viewMode = mode;
    },

    async selectCategory(parentCategory, currentCategory) {
      let categories = [...this.categoryData];

      let localState = categories.map((category) => {

        return category.slug !==parentCategory
          ? category
          : {
            ...category,
            isOpen: true,
            children: category?.children.map((subItem) => {
              return subItem.slug === currentCategory
                ? {
                  ...subItem,
                  isSelected: !subItem.isSelected
                }
                : {
                  ...subItem
                };
            })
          };
      });

      this.makeCategoryData(localState);

      const getSelectedCategory = this.categoryData;

      let parent = getSelectedCategory.find((parent) => parent.slug === parentCategory);

      parent?.children?.find((subCategory) => subCategory.isSelected);

      let sItems = [];

      getSelectedCategory.map((item) => {
        return {
          ...item,
          children: item.children.map((child) => {
            if (child.isSelected) {
              sItems.push(child.id);
            }
            return {
              ...child,
            };
          })
        };
      });

      this.resetPagination();
      await this.applyFilter({ type: 'category', data: sItems });
      this.applyFilter({ type: 'searchString', data: '' });
      await this.updateData();
    },
  },
});
</script>

<style lang="scss">
.filter-header, .v-expansion-panel-header__icon i{
  color: #ffffff !important;
}
</style>
