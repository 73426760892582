<template>
  <v-card
    class="mx-auto cursor-pointer"
    max-width="100%"
    outlined
  >
    <v-list-item>
      <v-list-item-avatar
        color="grey"
        size="80"
        tile
      />
      <v-list-item-content>
        <v-card-subtitle class="item-title">
          <div>Ford Mustang GT Premium</div>
          <div>{{ 50000 | currency }}</div>
        </v-card-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-card-actions class="align-center justify-center">
      <v-rating
        v-model="rating"
        background-color="grey darken-1"
        class="d-flex justify-center align-center text-center"
        color="yellow darken-3"
        empty-icon="$ratingFull"
        half-increments
        size="25"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PopularProduct',
  data: () => ({
    rating: 4.5
  }),
};
</script>

<style lang="scss" scoped>
.item-title {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
}
</style>
